import { useLocation } from "react-router-dom";

import { logoutUser } from "../../../infrastructure/authentication";

import ScenarioHeader from "../scenario/ScenarioHeader";
import HeaderStructure from "./HeaderStructure/HeaderStructure";
import BonusCalculationHeader from "../bonusCalculation/BonusCalculationHeader";
import ComponentViewHeader from "../component/ComponentViewHeader";
import EmployeeDetailHeader from "../employeeDetail/EmployeeDetailHeader";
import EvaluationsHeader from "../../../organisms/EvaluationsHeader";

function Header() {
  const handleLogout = async () => {
    await logoutUser();
  };

  const renderHeader = () => {
    const location = useLocation();
    const path = location.pathname;

    switch (path.split("/")[1]) {
      case "scenarioSelected":
      case "ListScenarios":
      case "scenario": {
        return (
          <HeaderStructure>
            <ScenarioHeader />
          </HeaderStructure>
        );
      }
      case "bonusCalculation": {
        return (
          <HeaderStructure>
            <BonusCalculationHeader />
          </HeaderStructure>
        );
      }
      case "components": {
        return (
          <HeaderStructure>
            <ComponentViewHeader />
          </HeaderStructure>
        );
      }
      case "employee-detail":
      case "component-detail": {
        return (
          <HeaderStructure>
            <EmployeeDetailHeader />
          </HeaderStructure>
        );
      }
      case "feedback-round":
      case "your-feedback-rounds":
      case "evaluations":
      case "feedback":
      case "feedback-results":
      case "evaluation-request":
      case "all-feedback":
      case "home": {
        return (
          <EvaluationsHeader
            onLogout={() => {
              void handleLogout();
            }}
          />
        );
      }
      case "login": {
        return undefined;
      }
      default:
        return <HeaderStructure></HeaderStructure>;
    }
  };

  return <div>{renderHeader()}</div>;
}

export default Header;
