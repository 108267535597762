import { FileWithPath } from "react-dropzone/.";
import ApiHelper from "../infrastructure/implementation/apiHelper";
import { performanceUrls } from "../config/performanceUrls";

export const uploadCSV = async (file: FileWithPath) => {
  const api = new ApiHelper();
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await api.request(
      "post",
      `${performanceUrls.scenario}/calculation/csv`,
      formData,
    );
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
