import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useDropzone,
  FileRejection,
  FileWithPath,
  Accept,
} from "react-dropzone";
import { useCallback, useState } from "react";
import { onNotify } from "../../presentation/helpers/notification-utils";

export interface CSVUploadModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (file: FileWithPath | null) => Promise<void>;
  accept: Accept;
  maxFiles?: number;
}

const hanldeRejection = (fileRejections: FileRejection[]) => {
  const message = { success: "", error: "" };
  message.error = fileRejections[0].errors[0].message;
  onNotify(message);
};

const message = { success: "", error: "" };

export const FileUploadModal = ({
  open,
  handleClose,
  handleSubmit,
  accept,
  maxFiles,
}: CSVUploadModalProps) => {
  const [selectedFile, setSelectedFile] = useState<FileWithPath | null>(null);
  const onDrop = useCallback((acceptedFiles: readonly FileWithPath[]) => {
    try {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    } catch (error) {
      message.error = "Error uploading file";
      onNotify(message);
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      maxFiles,
      accept,
      onDropRejected: hanldeRejection,
    });

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        closeAfterTransition={false}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            (async function () {
              await handleSubmit(selectedFile);
            })().catch((error) => {
              console.error(error);
            });
          },
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload CSV</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <DialogContentText>
            Upload a CSV file to import data.
          </DialogContentText>
          <Box
            {...getRootProps()}
            border="2px dashed"
            borderColor={isDragActive ? "primary.main" : "text.disabled"}
            borderRadius="10px"
            height="200px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            data-testid="dropzone"
          >
            <input {...getInputProps()} name="file-input" id="file-input" />
            {isDragActive ? (
              <Typography variant="body1">Drop the file here ...</Typography>
            ) : (
              <Typography variant="body1">
                Drag and drop your CSV file here, or click to select the file
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              variant="body1"
              component="span"
              sx={{
                ":hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              {acceptedFiles.map((file: FileWithPath) => (
                <Typography key={file.path}>{file.path}</Typography>
              ))}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Upload</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
