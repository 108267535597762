import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../atoms/Button";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";

import { navigateIfAuthenticated } from "../../utils/authChecker";
import { getUserPermissions } from "../../infrastructure/authorization";
import { useUserAPI } from "../../context/userAPIContext";
import logoIoet from "../../presentation/assets/performance-logo.png";
import { ADMIN_ROLE, INSPECTOR_ROLE } from "../../utils/constants";

import styles from "./styles.module.css";

export interface HeaderProps {
  onLogout: () => void;
}

const BASE_NAV_OPTIONS = [
  {
    name: "Home",
    link: "/home",
  },
  {
    name: "Peer & Self Evaluations",
    link: "/evaluations",
  },
  {
    name: "Your Feedback",
    link: "/feedback",
  },
];

const ADMIN_NAV_OPTIONS = [
  {
    name: "Feedback Round",
    link: "/feedback-round",
  },
  {
    name: "Your Feedback Rounds",
    link: "/your-feedback-rounds",
  },
  {
    name: "Evaluation Edition Request",
    link: "/evaluation-request",
  },
];

const INSPECTOR_NAV_OPTIONS = [
  {
    name: "All Feedback",
    link: "/all-feedback",
  },
];

export default function EvaluationsHeader({ onLogout }: HeaderProps) {
  const [roles, setRoles] = useState<string[]>([]);
  const navigate = useNavigate();
  const { checkUserAuthentication } = useUserAPI();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    getUserPermissions()
      .then((userPermissions) => {
        const roles = getRoles(userPermissions);
        setRoles(roles);
      })
      .catch(() => {});
  }, []);

  const getRoles = (userPermissions: any) => {
    return userPermissions.roles.performance as string[];
  };

  const navigationOptions = BASE_NAV_OPTIONS.concat(
    roles.includes(ADMIN_ROLE) ? ADMIN_NAV_OPTIONS : [],
    roles.includes(INSPECTOR_ROLE) ? INSPECTOR_NAV_OPTIONS : [],
  );

  return (
    <header className={styles.header}>
      <div className={styles.logoSpace}>
        <img
          alt="Performance"
          src={logoIoet}
          width="250"
          height="auto"
          data-testid="logo"
        />
      </div>

      <UnidimensionalLayout
        style={{ flexWrap: "wrap" }}
        direction="row"
        justifyContent="center"
        spacing={3}
        className={styles.navItemsSpace}
      >
        {navigationOptions.map(({ name, link }, index) => {
          const classNames =
            (path === link ? styles.currentLocation : "") + " redirect";
          const handleClick = () => {
            if (path !== link) {
              void (async () => {
                await navigateIfAuthenticated(link, navigate);
              })();
            }
          };
          return (
            <a
              data-testid="nav-item"
              key={index}
              className={classNames}
              onClick={handleClick}
            >
              {name}
            </a>
          );
        })}
      </UnidimensionalLayout>
      <div className={styles.buttonSpace}>
        <Button
          onClick={() => {
            onLogout();
            checkUserAuthentication();
            navigate("/login");
          }}
          className="button-primary-light-blue"
        >
          Logout
        </Button>
      </div>
    </header>
  );
}
