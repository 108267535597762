import { useEffect, useState } from "react";
import NextAndPreviousActions from "../../molecules/NextAndPreviousActions";
import ContainerAtom from "../../atoms/Container";
import TitleAtom from "../../atoms/Title";
import DividerAtom from "../../atoms/Divider";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import SharedFeedbackList from "../../organisms/SharedFeedbackList";
import { FeedbackRound } from "../../domain/entities/FeedbackRound";
import { Question, QuestionsAllcategory } from "../../domain/entities/Question";
import { evaluationServices } from "../../services/evaluationServices";
import PaperSurfaceAtom from "../../atoms/PaperSurface";
import SelectionToggleAutocomplete from "../../molecules/SelectionToggleAutocomplete";
import AnswerContainerOrganism from "../../organisms/AnswerContainer";
import LoadingMolecule from "../../molecules/Loading";
import { Answer } from "../../domain/entities/Answer";
import "./styles.css";

interface FREmployeeSelected {
  label: string;
  value: string;
}

export const FeedbackInspectionScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [peerQuestions, setPeerQuestions] = useState<Question[]>([]);
  const [selfQuestions, setSelfQuestions] = useState<Question[]>([]);
  const [peerAnswers, setPeerAnswers] = useState<Answer[]>([]);
  const [selfAnswers, setSelfAnswers] = useState<Answer[]>([]);
  const [sharedFeedbackRounds, setSharedFeedbackRounds] = useState<
    FeedbackRound[]
  >([]);
  const [selectedFeedbackRoundId, setselectedFeedbackRoundIdId] =
    useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = useState<FREmployeeSelected>({
    label: "",
    value: "",
  });
  const [employeeOptions, setEmployeeOptions] = useState<FREmployeeSelected[]>(
    [],
  );
  const [employeePositionInList, setEmployeePositionInList] =
    useState<number>(-1);

  useEffect(() => {
    fetchFeedbackRounds().finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedFeedbackRoundId !== null && selectedFeedbackRoundId !== "") {
      fetchFeedbackRoundData(selectedFeedbackRoundId)
        .then(({ feedbackEvaluatorEmployees }) => {
          const searchOptions = feedbackEvaluatorEmployees.map((employee) => {
            return { label: employee.name, value: employee.externalId };
          });
          setEmployeeOptions(searchOptions);
        })
        .catch(() => {
          setEmployeeOptions([]);
        });
    }
  }, [selectedFeedbackRoundId]);

  const fetchFeedbackRoundData = async (feedbackRoundId: string) => {
    const feedbackEvaluatorEmployees =
      await evaluationServices.getEvaluatorsFromFeedbackRound(feedbackRoundId);
    return { feedbackEvaluatorEmployees };
  };

  const fetchSharedFeedbackRounds = async () => {
    const response = await evaluationServices.getSharedFeedbackRounds();
    return response;
  };

  const fetchFeedbackRounds = async () => {
    const sharedFeedbackRounds = await fetchSharedFeedbackRounds();
    sharedFeedbackRounds.sort(
      (currentFeedbackRound, nextFeedbackRound) =>
        new Date(nextFeedbackRound.endDate).getTime() -
        new Date(currentFeedbackRound.endDate).getTime(),
    );
    const firstElementArray = sharedFeedbackRounds.slice(0, 1); // modify it later, now just show the last FR

    setSharedFeedbackRounds(firstElementArray);
  };

  const fetchQuestions = async (
    feedbackRoundId: string,
  ): Promise<QuestionsAllcategory> => {
    const questions: QuestionsAllcategory =
      await evaluationServices.getEvaluationQuestions(feedbackRoundId);
    return questions;
  };

  const fetchAnswers = async (
    feedbackRoundId: string,
    employeeExternalId: string,
  ) => {
    return await evaluationServices.getAnswersAndEditedAnswers(
      feedbackRoundId,
      employeeExternalId,
    );
  };

  const fetchQuestionsAndAnswers = async (
    selectedFeedbackRoundId: string,
    employeeExternalId: string,
  ) => {
    const questions = await fetchQuestions(selectedFeedbackRoundId);
    const answers = await fetchAnswers(
      selectedFeedbackRoundId,
      employeeExternalId,
    );
    return { questions, answers };
  };

  const setEmployeeFeedbackRoundValues = (
    selectedEmployeeId: string,
    employeePosition: number,
  ) => {
    setEmployeePositionInList(employeePosition);

    setIsLoading(true);
    fetchQuestionsAndAnswers(selectedFeedbackRoundId, selectedEmployeeId)
      .then((responseData) => {
        setSelfQuestions(responseData.questions.selfQuestions);
        setPeerQuestions(responseData.questions.peerQuestions);
        setSelfAnswers(responseData.answers.selfAnswers);
        setPeerAnswers(responseData.answers.peerAnswers);
      })
      .catch(() => {
        setEmployeeOptions([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeEmployeeOnPosition = (position: "next" | "previous") => {
    const currentEmployeePosition = employeePositionInList;
    const newEmployeePosition =
      currentEmployeePosition + (position === "next" ? 1 : -1);
    const selectedEmployee = employeeOptions.find(
      (employee, index) => index === newEmployeePosition,
    );
    if (selectedEmployee !== undefined) {
      setEmployeeFeedbackRoundValues(
        selectedEmployee.value,
        newEmployeePosition,
      );
      setSelectedEmployee(selectedEmployee);
    }
  };

  const handleSelectEmployee = (externalId: string) => {
    const choosedEmployee = employeeOptions.find((employee) => {
      return employee.value === externalId;
    });
    if (choosedEmployee?.value != null) {
      const newEmployeePosition = employeeOptions.findIndex((employee) => {
        return employee.value === choosedEmployee.value;
      });
      setEmployeeFeedbackRoundValues(
        choosedEmployee.value,
        newEmployeePosition,
      );
    }
    setSelectedEmployee(
      choosedEmployee ?? {
        label: "",
        value: "",
      },
    );
  };

  const emptyPage = (
    <p className="contentPadding  centerText">
      <i>Press search to show results</i>
    </p>
  );

  return (
    <>
      <ContainerAtom disableGutters maxWidth="xl">
        <TitleAtom variant="h3" gutterBottom className="centered-top-padding">
          All Feedback Review
        </TitleAtom>
        <DividerAtom flexItem />
        <UnidimensionalLayout className="grid-layout" alignItems="flex-start">
          <ContainerAtom disableGutters>
            <TitleAtom variant="h5" gutterBottom className="padding-small">
              Feedback Rounds
            </TitleAtom>
            <SharedFeedbackList
              sharedFeedbackRounds={sharedFeedbackRounds}
              onClick={setselectedFeedbackRoundIdId}
              selectedFeedbackRoundId={selectedFeedbackRoundId}
            />
          </ContainerAtom>
          <DividerAtom orientation="vertical" flexItem />
          <ContainerAtom disableGutters>
            {selectedFeedbackRoundId !== "" ? (
              <>
                <UnidimensionalLayout
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    width: "100%",
                    marginBottom: "32px",
                    marginTop: "32px",
                  }}
                >
                  <SelectionToggleAutocomplete
                    options={employeeOptions}
                    showButton={false}
                    value={selectedEmployee}
                    onAdd={handleSelectEmployee}
                    className="responsive-container"
                  />
                  <NextAndPreviousActions
                    onNext={() => {
                      handleChangeEmployeeOnPosition("next");
                    }}
                    onPrevious={() => {
                      handleChangeEmployeeOnPosition("previous");
                    }}
                    nextLabel="Next employee"
                    previousLabel="Previous employee"
                    disabled={{
                      previous: employeePositionInList <= 0,
                      next:
                        employeePositionInList >= employeeOptions.length - 1,
                    }}
                  />
                </UnidimensionalLayout>
                {selectedEmployee.label === "" ? (
                  emptyPage
                ) : (
                  <>
                    <UnidimensionalLayout
                      direction="column"
                      alignItems="stretch"
                      style={{
                        width: "100%",
                        marginBottom: "16px",
                        marginTop: "16px",
                      }}
                    >
                      <h2>{`Employee: ${selectedEmployee.label}`}</h2>
                    </UnidimensionalLayout>
                    <PaperSurfaceAtom
                      className={"paperContainer"}
                      square={true}
                      variant="outlined"
                      elevation={0}
                    >
                      <UnidimensionalLayout direction="column">
                        <AnswerContainerOrganism
                          answers={selfAnswers}
                          questions={selfQuestions}
                          title="Self-evaluation"
                          className="spacing-bottom-px"
                          message="You do not have a self-evaluation"
                        />
                        <AnswerContainerOrganism
                          answers={peerAnswers}
                          questions={peerQuestions}
                          title="Peer feedback"
                          className="spacing-bottom-px spacing-bottom-percentage"
                          message="You have not received feedback"
                        />
                      </UnidimensionalLayout>
                    </PaperSurfaceAtom>
                  </>
                )}
              </>
            ) : sharedFeedbackRounds.length > 0 ? (
              <p className="centered-spacing">
                Select a Feedback round to show results
              </p>
            ) : (
              <p className="centered-spacing">
                The Feedback round has not been shared yet
              </p>
            )}
          </ContainerAtom>
        </UnidimensionalLayout>
      </ContainerAtom>
      <LoadingMolecule condition={isLoading} />
    </>
  );
};
