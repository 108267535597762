import { Fragment, useState } from "react";
import AtomicButton from "../../atoms/Button";
import UnidemensionalLayout from "../../atoms/UnidimensionalLayout";
import AutocompleteAtom from "../../atoms/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectionToggleAutocompleteProps {
  options: SelectOption[];
  onAdd: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonLabel?: string;
  buttonClassName?: string;
  showButton?: boolean;
  value?: SelectOption;
  className?: string;
}

export default function SelectionToggleAutocomplete({
  options,
  onAdd,
  disabled,
  style,
  buttonLabel,
  buttonClassName,
  showButton = true,
  value,
  className,
}: SelectionToggleAutocompleteProps) {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  return (
    <UnidemensionalLayout
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={style}
      className={className}
    >
      <>
        <AutocompleteAtom
          disabled={disabled}
          options={options}
          style={{
            width: "40%",
            marginRight: "1%",
          }}
          onChange={(
            event: any,
            data: { label: string; value: any } | null,
          ) => {
            setSelectedLabel(data?.label ?? "");
            setSelectedValue(data?.value);
            onAdd(data?.value);
          }}
          renderInput={(params: any) => {
            return (
              <TextField
                {...params}
                label={"Select coworker"}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            );
          }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={
            value != null
              ? value
              : { label: selectedLabel, value: selectedValue }
          }
        />
      </>
      {showButton && (
        <AtomicButton
          className={buttonClassName}
          disabled={disabled}
          onClick={() => {
            setSelectedLabel("");
            return onAdd(selectedValue);
          }}
        >
          {buttonLabel}
        </AtomicButton>
      )}
    </UnidemensionalLayout>
  );
}

SelectionToggleAutocomplete.defaultProps = {
  options: [
    {
      label: "John Doe",
      value: "A",
    },
    {
      label: "Jane Doe",
      value: "B",
    },
  ],
  onAdd: () => {},
  style: {
    marginBottom: "10px",
  },
  buttonLabel: "Add",
  buttonClassName: "button-primary-orange",
  showButton: true,
};
